import Lottie from 'lottie-react';
import React from 'react';
import "./LoadingNotification.scss";
import loadAnim from "../../assets/animations/7773-loading.json";

const LoadingNotification = () => {
    return (
        <div className="LoadingNotification">
            <Lottie animationData={loadAnim}  loop={true} className='load-icon' />
             Cargando...
        </div>
    );
}
 
export default LoadingNotification;