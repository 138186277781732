import PinkProfileImage from '../profileImgs/profileImg_1.png';
import BlueProfileImage from '../profileImgs/profileImg_2.png';

const arrayProfileImages = [
    {
        name: 'Pink',
        path: PinkProfileImage,
        alt: 'PinkProfileImage'
    },
    {
        name: 'Blue',
        path: BlueProfileImage,
        alt: 'BlueProfileImage'
    }
];

export default arrayProfileImages;