import DeviceDetector from "device-detector-js";
import {version} from "../pckg.json";

export const gatherEnvInfo = ()=>{
    let browser = "";
    try {
        browser = (new DeviceDetector()).parse(window.navigator.userAgent);
        browser.userAgent = window.navigator.userAgent;
    } catch (e) {
        browser = {browserReadError: e.message}
    }

    browser.appVersion = version;
    browser.userAgent = window.navigator.userAgent;

    return {
        location: window.location.href,
        browser,
    };
}
