import React from 'react';
import './TideToaster.scss';
import _ from 'lodash';
import classNames from 'classnames';
import Lottie from "lottie-react";
import successAnim from '../../assets/animations/54092-success';
import errorAnim from '../../assets/animations/14651-error-animation.json';
import infoAnim from '../../assets/animations/12246-info-edited.json';


class TideToaster extends React.Component {

    constructor(props) {
        super(props);
        this.state = { messages: [] };
    }

    clearMessage = (id) => {
        const index = _.findIndex(this.state.messages, (msg) => msg.id === id);
        if (index === -1)
            return;
        const messages = [...this.state.messages];
        messages.splice(index, 1);
        this.setState({ messages });
    };

    handleClearMessage = (e)=>{
        this.clearMessage( e.currentTarget.dataset.id );
    }

    showMessage = (content, title, config) => {

        const message = { content, ...config, id: Math.random()+'' };
        this.setState({
            messages: [...this.state.messages, message]
        });
        setTimeout(() => {
            this.clearMessage(message.id);
        }, Number(config.duration) || 5000);

        return message;
    };

    error = (content, title, config = {}) => {
        const Icon = config.Icon || (()=><Lottie animationData={errorAnim}  loop={false} />);
        this.showMessage(content, title, { type: 'error', ...config, Icon });
    };

    success = (content, title, config = {}) => {
        const Icon = config.Icon || (()=><Lottie animationData={successAnim}  loop={false} />);
        this.showMessage(content, title, { type: 'success', ...config, Icon });
    };

    info = (content, title, config = {}) => {
        const Icon = config.Icon || (()=><Lottie animationData={infoAnim} style={{transform:"scale(0.6)"}} />);
        this.showMessage(content,  title,{ type: 'info', ...config, Icon });
    };

    render() {

        const { messages } = this.state;

        return (
            <div className={"TideToaster"}>
                {messages?.map(message=>
                    <div className={classNames('message-bar', message.className, message.type)}
                         key={message.id}
                         data-id={message.id}
                         onClick={this.handleClearMessage}>
                        <span className='left-bar' />
                        {message.Icon && <div className='toast-icon'><message.Icon /></div>}
                        <div className='toast-content'>
                            {message.title && <p className='toast-title'>{message.title}</p>}
                            {message.content}
                        </div>
                    </div>
                )}

            </div>
        );
    }
}

export default TideToaster;
