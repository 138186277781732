import React, {useCallback, useRef} from 'react';
import {createPortal} from "react-dom";
import './Modal.scss';
import useBodyClass from "../../hooks/useBodyClass";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";

const Modal = ({onClose, children, title, className}) => {

    useBodyClass('overflow-hidden');

    // Call onClose when the user clicks on the black background
    const backgroundRef = useRef();
    const handleBackgroundClick = useCallback((e)=>{
        if( e.target !== backgroundRef.current) return;//Prevent closing when the user clicks on the content
        onClose && onClose();
    },[onClose]);

    return createPortal(<>
        <div className={classNames("Modal", className)} onClick={handleBackgroundClick} ref={backgroundRef}>
            <div className='inner-modal'>
                <button onClick={onClose} className='close-btn'>
                    <FontAwesomeIcon icon={faTimes} className="example-copy close-icon text-danger"/>
                </button>
                {title && <h1 className='title'>{title}</h1>}
                {children}
            </div>
        </div>
    </>, document.body);
};

export default Modal;
