import React from 'react';

const BooleanButton = ( {input} ) => {
  return (
    <div className="BooleanButton inputContainer">
      <button type="button" className={`btn ${input.value?'btn-danger':'btn-white'} mt-2 btn-outline-secondary shadow-sm`} onClick={input.onChange}>
          {input.placeholder}
      </button>
    </div>
  );
}
 
export default BooleanButton;