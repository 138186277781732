import React from 'react';
import {ApiContext} from "../../services/api/api-config";
import {connect} from "react-redux";
import './ErrorBoundary.scss';
import {gatherEnvInfo} from "../../services/errorUtils";

class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props);
        this.state = {broken: false};
    }
    static getDerivedStateFromError() {
        return {broken: true};
    }

    componentDidCatch(error, errorInfo) {

        if ( process.env.REACT_APP_BUILD === 'prod' && !this.errorSent) {

            const info = gatherEnvInfo();
            if (this.api.requestLog && this.api.requestLog)
                info.requestLog = this.api.requestLog;

            this.props.api.error.send(error, errorInfo.componentStack, this.props.me, info)
                .then(() => {
                    this.setState({sendingLogs: false});
                });
        }
        this.errorSent = true;
    }

    render(){

        if(!this.state.broken)
            return this.props.children;

        return (
            <div className='ErrorBoundary'>
                <div className='left sides'>
                </div>
                <div className='right sides'>
                    <h1 className='title'>¡Oh no! Parece que hubo un error.</h1>
                    <p className='message'>Algo salió mal en la aplicación, pero los detalles del error ya fueron enviados a soporte técnico</p>
                    <div className='but-container'>
                        <a href='/'><button className='button'>Regresar al inicio</button></a>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({api:{me}})=>({me});

const ErrorBoundaryWithApi = (props)=>
    <ApiContext.Consumer>
        {(api)=><ErrorBoundary api={api} {...props}/>}
    </ApiContext.Consumer>;

export default connect(mapStateToProps)(ErrorBoundaryWithApi);
